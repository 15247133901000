<template>
  <v-app style="background-color: #dcebff">
    <div class="my-auto">
      <v-img
        src="../assets/EmptyState.svg"
        :max-width="$vuetify.breakpoint.smAndUp ? '30%' : '85%'"
        class="mx-auto mt-n4"
      />
      <div
        class="text-center font-weight-medium text-h5 text-sm-h3 px-2"
        style="color: #371a45"
      >
        {{ $t('alerta.alerta_paginanaoencontrada') }}
      </div>

      <div
        class="
          text-center
          font-weight-regular
          text--secondary
          mt-2
          text-subtitle-2 text-sm-h6
          px-6
        "
      >
        {{ $t('alerta.alerta_naoencontrada') }}
        <!-- <br v-if="$vuetify.breakpoint.smAndUp" />
        Recarregue a página para um dois tres quatro cinco seis sete oito -->
      </div>
      <div class="text-center mt-6">
        <v-btn
          rounded
          color="#371a45"
          dark
          class="mx-auto"
          large
          @click="toHome()"
        >
          {{ $t('botoes.botao_voltarpaginainicia') }}</v-btn
        >
      </div>
      <!-- <v-btn
        fab
        fixed
        left
        class="elevation-2"
        color="rgba(34, 15, 45, 0.28)"
        style="top: 16px; color: #371a45"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn> -->
    </div>
  </v-app>
</template>

<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="css" scoped></style>
